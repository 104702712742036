.Title {
    height: calc(100vh - 80px);

    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Title-logo {
    width: inherit;
    height: 70vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .Title-logo {
        animation: Title-logo-spin infinite 20s linear;
    }
}

@keyframes Title-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}